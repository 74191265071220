import analytics from '@capturi/analytics'
import { conversationsAPI } from '@capturi/api-conversations'
import request, { getErrorMessage } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Flex, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { format } from 'date-fns/fp'
import { type FC, useState } from 'react'

type Options = {
  teamName: string
  teamUid: string
  users: { id: string; name: string }[]
  onClose: () => void
}

type Props = Options & BaseModalComponentProps
const toTime = format("yyyy-MM-dd'T'HH:mm")

export const BulkBackSyncTeamConversationsModal: FC<Props> = ({
  onClose,
  users,
  teamName,
  teamUid,
}) => {
  const [date, setDate] = useState(toTime(new Date()))

  const [inProgress, setInProgress] = useState(false)
  const [progress, setProgress] = useState(0)
  const toast = useToast()
  const [moveConversationsValue, setMoveConversationsValue] = useState<
    'noDate' | 'withDate'
  >('noDate')

  const handleSync = async (): Promise<void> => {
    setInProgress(true)
    analytics.event('organization_teams_roleSync', {
      fromDate: new Date(date),
    })
    //looks like some kind of optimization break the count in the tooltip when using progress from setState
    let internalProgress = 0
    for (let index = 0; index < users.length; index++) {
      const { id, name } = users[index]
      try {
        setProgress((p) => p + 1)
        await request(
          conversationsAPI.syncTeamOnConversations(id, teamUid, new Date(date)),
        )
        internalProgress = internalProgress + 1
      } catch (error) {
        const errorMsg = getErrorMessage(error)

        toast({
          status: 'error',
          title: t`Could not move conversations for ${name}`,
          description: errorMsg,
        })
      }
    }
    toast({
      status: 'success',
      title: t`Moved conversations for ${internalProgress} users`,
    })
    onClose()
  }

  const handleDate = (val: 'noDate' | 'withDate') => {
    setMoveConversationsValue(val)
    if (val === 'noDate') {
      setDate(toTime(new Date('2020-01-01T07:00')))
    } else if (val === 'withDate') {
      setDate(toTime(new Date()))
    }
  }

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Text>
              <Trans>
                Move conversations for {users.length} users to team {teamName}
              </Trans>
            </Text>
          </ModalHeader>
          <ModalCloseButton isDisabled={inProgress} />
          <ModalBody>
            <RadioGroup
              id="move-conversations"
              onChange={handleDate}
              value={moveConversationsValue}
            >
              <Stack>
                <Radio value="noDate" cursor="pointer">
                  <Trans>Move all conversations</Trans>
                </Radio>
                <Radio value="withDate" cursor="pointer">
                  <Trans>Move only conversations in specific time period</Trans>
                </Radio>
              </Stack>
            </RadioGroup>
            {moveConversationsValue === 'withDate' && (
              <Flex mt={2} alignItems="center">
                <Text color="gray.600">
                  <Trans>
                    Move {users.length} team members conversations since
                  </Trans>
                </Text>
                <Input
                  type="datetime-local"
                  onChange={(e) => setDate(e.target.value)}
                  value={date}
                  isDisabled={inProgress}
                  width="fit-content"
                  mx="2"
                  size="sm"
                />
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button isDisabled={inProgress} secondary onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              ml="2"
              isLoading={inProgress}
              loadingText={`${progress}/${users.length}`}
              primary
              onClick={handleSync}
            >
              <Trans>Move conversations</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
