import { useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { usePageTitle } from '@capturi/react-utils'
import { useOrganization } from '@capturi/stores'
import { PageHeading, Tab, TabList, Tabs } from '@capturi/ui-components'
import { select, t } from '@lingui/macro'
import React from 'react'
import { Outlet, useMatch, useNavigate } from 'react-router'
import { BaseTab, useTabsRouteState } from 'utils/useRoutableTabs'

import routes, { OrganisationPageView } from './routes'

const Organization: React.FC = () => {
  const { name: organizationName, organizationType } = useOrganization()
  const { enableText, useEmailChannelAsDefault } = useFeatureFlags()
  const { permissions } = useCurrentUser()
  usePageTitle(organizationName)
  const navigate = useNavigate()
  const routeMatch = useMatch('/settings/organization/:tabName')
  const { isArchiveOrg } = useFeatureFlags()

  const { tabIndex, getTab, tabs } = useTabsRouteState<OrganisationPageView>(
    () => {
      const routes = [
        {
          value: 'users',
          tab: t`Users`,
        },
        {
          value: 'agents',
          tab: t`Agents`,
          hide: isArchiveOrg,
        },
        {
          value: 'teams',
          tab: t`Teams`,
          hide: isArchiveOrg,
        },
        {
          value: 'api',
          tab: t`API & Webhooks`,
          hide: isArchiveOrg,
        },
        {
          value: 'deletion-policy',
          tab: t`Deletion Policy`,
        },
        {
          value: 'repeat-calls',
          tab: t`Repeat calls policy`,
          hide: (enableText && useEmailChannelAsDefault) || isArchiveOrg, // text-only org
        },
        {
          value: 'audit-log',
          tab: t`Audit Log`,
        },
        {
          value: 'customer-data',
          tab: select(organizationType, {
            public: 'Citizen Data',
            other: 'Customer Data',
          }),
          hide: isArchiveOrg,
        },
        {
          value: 'importer',
          tab: t`Importer`,
          hide: !(enableText && permissions.text) || isArchiveOrg,
        },
      ]

      return routes.filter(
        ({ hide }) => !hide,
      ) as BaseTab<OrganisationPageView>[]
    },
    routeMatch?.params.tabName as OrganisationPageView,
  )

  const handleTabsChange = (index: number): void => {
    const tabName = getTab(index).value
    navigate(routes.tab(tabName))
  }

  return (
    <div>
      <PageHeading mb={10}>{organizationName}</PageHeading>
      <Tabs mb="4" index={tabIndex} onChange={handleTabsChange} isLazy>
        <TabList>
          {tabs.map((o) => (
            <Tab key={o.value}>{o.tab}</Tab>
          ))}
        </TabList>
      </Tabs>
      <Outlet />
    </div>
  )
}

export default Organization
