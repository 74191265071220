import { DEFAULT_REPEAT_CALL_SETTINGS } from '@capturi/api-organization'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { type FC, useState } from 'react'
import isEqual from 'react-fast-compare'
import {
  useRepeatCallsBlacklist,
  useSystemSettings,
  useUpdateRepeatCallsBlacklist,
  useUpdateSystemSettings,
} from '../../hooks/useUpdateSystemSettings'
import { SystemSettings } from '../../types'
import BlacklistPhoneNumbers from './Components/BlacklistPhoneNumbers'
import DefaultScenario from './Components/DefaultScenario'
import FollowUp from './Components/FollowUp'

const selectRepeatCallPolicy = (
  data: SystemSettings,
): {
  maxDistance: number
  minDistance: number
} => {
  const { maxDistance, minDistance } = data.repeatCall || {}

  return {
    maxDistance: maxDistance || DEFAULT_REPEAT_CALL_SETTINGS.maxDistance,
    minDistance: minDistance || DEFAULT_REPEAT_CALL_SETTINGS.minDistance,
  }
}

const RepeatCalls: FC = () => {
  const { data: initialRepeatCalls } = useSystemSettings({
    select: selectRepeatCallPolicy,
  })
  const { mutate: updateSystemSettings, isPending: systemSettingsIsPending } =
    useUpdateSystemSettings()
  const { data: repeatCallsBlacklist } = useRepeatCallsBlacklist()
  const { mutate: updateRepeatCallBlacklist, isPending: blacklistIsPending } =
    useUpdateRepeatCallsBlacklist()
  const [dataInitialized, setDataInitialized] = useState(false)

  const toast = useToast()

  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(
    repeatCallsBlacklist?.customers ?? [],
  )

  if (repeatCallsBlacklist?.customers && dataInitialized === false) {
    setPhoneNumbers(repeatCallsBlacklist.customers)
    setDataInitialized(true)
  }

  const [repeatCall, setRepeatCall] = useState<{
    maxDistance: number
    minDistance: number
  }>(initialRepeatCalls)
  const hasRepeatCallsChanges = !isEqual(initialRepeatCalls, repeatCall)
  const hasBlacklistsChanges = !isEqual(
    repeatCallsBlacklist?.customers || [],
    phoneNumbers,
  )

  const hasChanges = hasRepeatCallsChanges || hasBlacklistsChanges

  const handleOk = (): void => {
    if (hasRepeatCallsChanges) {
      updateSystemSettings(
        {
          repeatCall: {
            ...repeatCall,
            minDistance:
              repeatCall.minDistance === 0 ? null : repeatCall.minDistance,
          },
        },
        {
          onSuccess: () => {
            toast({
              status: 'success',
              title: t`Successfully updated repeat call policy`,
            })
          },
          onError: (error) => {
            toast({
              status: 'error',
              title: t`Failed to update repeat call policy`,
              description: error.message,
            })
          },
        },
      )
    }

    if (hasBlacklistsChanges) {
      updateRepeatCallBlacklist(phoneNumbers, {
        onSuccess: () => {
          toast({
            status: 'success',
            title: t`Successfully updated repeat call blacklist`,
          })
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: t`Failed to update repeat call blacklist`,
            description: error.message,
          })
        },
      })
    }
  }

  const onScenarioChange = (value: number) => {
    setRepeatCall({ ...repeatCall, maxDistance: value })
  }

  const onChange = (value: number) => {
    setRepeatCall({ ...repeatCall, minDistance: value })
  }

  return (
    <Flex flexDir="column">
      <HStack gap={8} align="start">
        <VStack pb={4} w="50%" align="flex-start">
          <Box mb={4}>
            <DefaultScenario
              onChange={onScenarioChange}
              value={repeatCall.maxDistance}
            />
          </Box>
          <Box mb={4}>
            <FollowUp onChange={onChange} value={repeatCall.minDistance} />
          </Box>
        </VStack>
        <Divider orientation="vertical" height="50px" borderColor="gray.200" />
        <VStack w="50%" align="flex-start">
          <BlacklistPhoneNumbers
            phoneNumbers={phoneNumbers}
            setPhoneNumbers={setPhoneNumbers}
          />
        </VStack>
      </HStack>
      <HStack justify="flex-end" w="100%">
        <Button
          isLoading={blacklistIsPending || systemSettingsIsPending}
          colorScheme="primary"
          onClick={handleOk}
          isDisabled={!hasChanges}
        >
          <Trans>Save repeat call policy</Trans>
        </Button>
      </HStack>
    </Flex>
  )
}

export default RepeatCalls
