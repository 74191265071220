import { Team } from '@capturi/core'
import request, { ResponseError } from '@capturi/request'
import { useUsers } from '@capturi/stores'
import {
  UseMutationResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useMemo } from 'react'

type TeamPayload = {
  uid: string
  name: string
}

export type DeleteTeamPayload = {
  teamUid: string
  when: Date
}

type DeletedTeam = {
  created: Date
  createdByUserUid: string
  isDeleted: boolean
  organizationUid: string
  teamUid: string
  name: string
  when: Date
}
const sort = Intl.Collator('da-DK').compare

const createUidSetSelector = (deletedTeam: DeletedTeam[]) =>
  new Map(deletedTeam.map((d) => [d.teamUid, d]))

export function useTeams(): {
  teams: Team[]
  isPending: boolean
} {
  const { isLoading, getUsersByTeamUid } = useUsers()

  const { data: teams = [], isPending: isTeamListPending } = useQuery({
    queryKey: ['organization', 'teams'],
    queryFn: async () => {
      const result = await request.get<Team[]>(
        'authentication/organization/teams',
      )
      return result
    },
  })

  const { data: deletedUids } = useQuery({
    queryKey: ['organization', 'deletedTeams'],
    queryFn: async () => {
      const result = await request.get<{ teams: DeletedTeam[] }>(
        'team/deleted?api-version=3.3',
      )
      return result.teams
    },
    select: createUidSetSelector,
  })

  const teamsWithMembers = useMemo(
    () =>
      teams
        .filter((t) => !t.isDeleted)
        .map<Team>((t) => {
          const deletedTeam = deletedUids?.get(t.uid)

          return {
            ...t,
            isDeleted: false,
            name: t.name,
            when: deletedTeam?.when,
            members: getUsersByTeamUid(t.uid)
              .filter(({ isDeleted }) => !isDeleted)
              .map(({ uid, name }) => ({
                id: uid,
                name,
              })),
          }
        })
        .sort((a, b) => sort(a.name, b.name)),
    [teams, deletedUids, getUsersByTeamUid],
  )

  return {
    teams: teamsWithMembers,
    isPending: isLoading || isTeamListPending,
  }
}

export const useCreateTeam = (): UseMutationResult<
  Team,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (name: string) => {
      return request.post<Team>(
        'authentication/organization/teams?api-version=3.3',
        {
          json: { name },
        },
      )
    },
    onSuccess: (newTeam) => {
      queryClient.setQueryData<Team[]>(['organization', 'teams'], (oldData) => {
        if (!oldData) {
          return [newTeam]
        }
        return [newTeam, ...oldData]
      })
    },
  })
}

export const useUpdateTeam = (): UseMutationResult<
  Team,
  ResponseError,
  TeamPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ name, uid }) => {
      return request.put<Team>(`authentication/organization/teams/${uid}`, {
        json: { name },
      })
    },
    onSuccess: (newTeam) => {
      queryClient.setQueryData<Team[]>(['organization', 'teams'], (oldData) => {
        if (!oldData) {
          return [newTeam]
        }
        return oldData.map((f) => (f.uid === newTeam.uid ? newTeam : f))
      })
    },
  })
}

export const useMarkTeamForDeletion = (): UseMutationResult<
  Team,
  ResponseError,
  DeleteTeamPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (payload: DeleteTeamPayload) => {
      return await request.post<Team>(
        `team/deleted/${payload.teamUid}/mark?when=${payload.when.toISOString()}&api-version=3.3`,
      )
    },
    onSuccess: (deletedTeam) => {
      queryClient.setQueryData<Team[]>(
        ['organization', 'deletedTeams'],
        (oldData) => {
          if (!oldData) {
            return [deletedTeam]
          }

          return [...oldData, deletedTeam]
        },
      )
      queryClient.invalidateQueries({
        queryKey: ['organization', 'deletedTeams'],
      })
    },
  })
}

export const useUnmarkTeamForDeletion = (): UseMutationResult<
  null,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (teamUid: string) => {
      return await request.delete<null>(
        `team/deleted/${teamUid}/unmark?api-version=3.3`,
      )
    },
    onSuccess: (_, teamUid) => {
      queryClient.setQueryData<DeletedTeam[]>(
        ['organization', 'deletedTeams'],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.filter((t) => t.teamUid !== teamUid)
        },
      )
      queryClient.invalidateQueries({
        queryKey: ['deletedTeams'],
      })
    },
  })
}
