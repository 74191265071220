import { Team } from '@capturi/core'
import { useModal } from '@capturi/use-modal'
import { HStack, IconButton, Td, Tooltip, Tr } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { DeleteTeamModal } from 'pages/Organization/components/modals/DeleteTeamModal'
import { FC, memo } from 'react'
import { MdDelete, MdEdit, MdSync } from 'react-icons/md'
import { BulkBackSyncTeamConversationsModal } from './BulkBackSyncTeamConversationsModal'
import TeamScheduledForDeletion from './TeamScheduledForDeletion'

const TeamsTableRow: FC<
  Team & { onEdit: (team: { uid: string; name: string }) => void }
> = ({ onEdit, ...props }) => {
  const [openDeleteTeamModal] = useModal(DeleteTeamModal)
  const [openBulkBackSyncTeamConversationsModal] = useModal(
    BulkBackSyncTeamConversationsModal,
  )
  return (
    <Tr>
      <Td>{props.name}</Td>
      <Td>
        {props.when ? (
          <TeamScheduledForDeletion when={props.when} teamUid={props.uid} />
        ) : (
          <>{props.members.map((m) => m.name).join(', ')}</>
        )}
      </Td>
      <Td>
        {!props.when && (
          <HStack>
            <Tooltip
              hasArrow
              isDisabled={!!props.members?.length}
              placement="top"
              label={t`There need to be members on the team`}
            >
              <IconButton
                isDisabled={!props.members?.length}
                size="sm"
                isRound
                icon={<MdSync />}
                aria-label={t`Sync teams conversations`}
                onClick={() =>
                  openBulkBackSyncTeamConversationsModal({
                    users: props.members,
                    teamName: props.name,
                    teamUid: props.uid,
                  })
                }
              />
            </Tooltip>
            <Tooltip
              hasArrow
              isDisabled={!props.createdByIntegration}
              placement="top"
              label={t`You cannot edit teams created be an integration`}
            >
              <IconButton
                isDisabled={props.createdByIntegration}
                size="sm"
                isRound
                icon={<MdEdit />}
                aria-label={t`Edit team`}
                onClick={() => onEdit({ uid: props.uid, name: props.name })}
              />
            </Tooltip>

            <Tooltip
              hasArrow
              isDisabled={
                props.createdByIntegration ? false : !props.members?.length
              }
              placement="top"
              label={
                props.createdByIntegration
                  ? t`You cannot delete a team created by an integration`
                  : t`You need to remove members before you can delete the team`
              }
            >
              <IconButton
                size="sm"
                isDisabled={
                  props.createdByIntegration ? true : !!props.members?.length
                }
                isRound
                icon={<MdDelete />}
                onClick={() =>
                  openDeleteTeamModal({
                    teamName: props.name,
                    teamUid: props.uid,
                  })
                }
                aria-label={t`Delete`}
                title={t`Delete`}
              />
            </Tooltip>
          </HStack>
        )}
      </Td>
    </Tr>
  )
}

export default memo(TeamsTableRow)
